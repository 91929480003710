.banner-container {
   width: 100%;
   background-color: #ffffff;
   overflow: hidden;
 }
 
 .slide-container {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   background-color: #F5F5F3;
   padding: 20px;
   box-sizing: border-box;
 }
 
 .slide-content {
   max-width: 450px;
   margin-right: 50px;
 }
 
 .slide-title {
   margin-bottom: 15px;
   font-size: 2rem;
   color: #000;
   font-weight: 700;
 }
 
 .slide-subtext {
   margin-bottom: 25px;
   font-size: 1.25rem;
   color: #666;
 }
 
 .slide-button {
   background-color: #262626;
   color: white;
   padding: 12px 24px;
   font-size: 1rem;
   font-weight: bold;
   border: none;
   cursor: pointer;
   transition: background-color 0.3s;
 }
 
 .slide-button:hover {
   background-color: #000;
 }
 
 .slide-image {
   flex-shrink: 0;
   margin-left: 50px;
 }
 
 .unique-image {
   max-width: 100%;
   height: auto;
 }
 
 .dots-container {
   position: absolute;
   top: 50%;
   left: 5%;
   transform: translateY(-50%);
 }
 
 .dots-container-mobile {
   position: absolute;
   top: 50%;
   left: 2%;
   transform: translateY(-50%);
 }
 
 .dot {
   width: 25px;
   height: 25px;
   border-right: 3px solid white;
   color: transparent;
   cursor: pointer;
 }
 
 .dot.active {
   color: #262626;
   border-color: #262626;
 }
.slick-dots {
   display: none !important;
}
 
 /* Responsive adjustments */
 @media (max-width: 576px) {
   .slide-container {
     flex-direction: row; /* Yatayda kalmasını sağlar */
     justify-content: space-between;
     padding: 15px;
   }
 
   .slide-content {
     max-width: 50%;
     margin-right: 10px;
     text-align: left;
   }
 
   .slide-image {
     margin-left: 10px;
     max-width: 50%;
     display: flex;
     justify-content: flex-end;
   }
 
   .slide-title {
     font-size: 1.75rem;
   }
 
   .slide-subtext {
     font-size: 1rem;
   }
 
   .slide-button {
     font-size: 0.875rem;
     padding: 10px 20px;
   }
 }
 